import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/driver.js@1.3.1/node_modules/driver.js/dist/driver.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/filepond@4.31.4/node_modules/filepond/dist/filepond.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/vercel/path0/frontend/node_modules/.pnpm/next-view-transitions@0.3.4_next@15.1.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_reac_uhh7tp7c26aa2thz2pxpv6aexu/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.1.0_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@19._wbs33il7pyls4ao7buw7yq7lma/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["RootLayout"] */ "/vercel/path0/frontend/packages/component/src/root-layout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DataDogAnalysis"] */ "/vercel/path0/frontend/src/analytics/DataDogAnalysis.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider","AuthReady"] */ "/vercel/path0/frontend/src/hooks/auth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PermissionProvider"] */ "/vercel/path0/frontend/src/hooks/permissions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactSection"] */ "/vercel/path0/frontend/src/layouts/base-layout/navigation/contact-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LegacyInformationSection"] */ "/vercel/path0/frontend/src/layouts/base-layout/navigation/legacy/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BaseLayoutProvider"] */ "/vercel/path0/frontend/src/layouts/base-layout/shared.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrentProjectIdProviderInternal"] */ "/vercel/path0/frontend/src/store/current-project-id/client.tsx");
